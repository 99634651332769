@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-container {
    @apply px-3 sm:px-6 md:px-8 xl:px-16 mx-auto max-w-[2520px];
  }

  .side-section-width {
    @apply hidden lg:block lg:w-[16.3%];
  }

  .main-section-width {
    @apply w-full lg:pr-6 lg:w-[83.7%];
  }

  .bottom-noti-height {
    @apply hidden lg:block lg:h-[13%];
  }

  .main-section-height {
    @apply w-full lg:pb-6 lg:h-5/6;
  }

  .button-size {
    @apply w-auto h-7 lg:h-8 px-3 lg:px-6 flex items-center justify-center text-white text-xs md:text-sm lg:text-base rounded break-keep;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar::-webkit-scrollbar-track {
  display: none;
}

.hidden-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}

.small-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: gray;
}

/* Track */
.small-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.small-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.small-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.small-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #000;
  border-radius: 10px;
}

.gap-x-14 {
  column-gap: 3.5rem; /* 56px */
}

.gap-x-5-pct {
  column-gap: 5%;
}

.gap-x-10-pct {
  column-gap: 10%;
}
